import Container_LiftedShadowAreaComponent from '../components/Container/viewer/skinComps/LiftedShadowArea/LiftedShadowArea.skin';


const Container_LiftedShadowArea = {
  component: Container_LiftedShadowAreaComponent
};


export const components = {
  ['Container_LiftedShadowArea']: Container_LiftedShadowArea
};

